import React from 'react';
import '../styles/Switch.css';

const Switch = ({ isOn, handleToggle, switchId }) => {
  return (
    <>
      <input
        checked={isOn}
        onChange={handleToggle}
        className="react-switch-checkbox"
        id={switchId}
        type="checkbox"
      />
      <label
        style={{ background: isOn && '#06D6A0' }}
        className="react-switch-label"
        htmlFor={switchId}
      >
        <span className={`react-switch-button`} />
      </label>
    </>
  );
};

export default Switch;