import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import config from '../../../config';
import { useFetchWrapper } from '../../../_helpers/fetchWrapper';

import Modal from 'react-modal';
import Switch from '../../Switch';
import LoadingSpinner from '../../partial/LoadingSpinner';
import '../../../styles/partial/modal/CreateTagModal.css';

const CreateTagModal = ({ isOpen, onRequestClose, tag  }) => {
  const { bandid } = useParams();
  const [tagId, setTagId] = useState(0);
  const [tagName, setTagName] = useState('Sample');
  const [backgroundColor, setBackgroundColor] = useState('#ffffff');
  const [textColor, setTextColor] = useState('#000000');
  const [tagImage, setTagImage] = useState(null);
  const [tagImageUrl, setTagImageUrl] = useState(null);
  const [isPublic, setIsPublic] = useState(true);

  const [loading, setLoading] = useState(false);

  const fetchWrapperRef = useRef(useFetchWrapper());

  useEffect(() => {
    if (tag) {
      setTagId(tag.ID);
      setTagName(tag.Word);
      setBackgroundColor(tag.BackgroundColor);
      setTextColor(tag.TextColor);
      setIsPublic(tag.IsPublic);
      setTagImage(tag.TagImage || null);
      setTagImageUrl(tag.ImageURL ? `${config.mediaBaseUrl}/${tag.ImageURL}` : null); // Set the existing image URL if it exists
    } else {
        // Reset to default values
        setTagId(0);
        setTagName('Sample');
        setBackgroundColor('#ffffff');
        setTextColor('#000000');
        setIsPublic(true);
        setTagImage(null);
        setTagImageUrl(null);
      }
  }, [tag]);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setTagImage(file);
    setTagImageUrl(URL.createObjectURL(file));
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setTagImage(file);
    setTagImageUrl(URL.createObjectURL(file));
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    setLoading(true);

    const uploadImage = async (tagId) => {
      if (tagImage) {
        const imageFormData = new FormData(e.target);
        imageFormData.append('file', tagImage);

        const formData = {}
        formData.id = tagId;
        try {
          setLoading(true);
          const imageResponse = await fetchWrapperRef.current.post(`${config.apiBaseUrl}/bands/${bandid}/tags/${tagId}/icon`, imageFormData);
          
          if (!imageResponse.ok) {
            throw new Error('Image upload failed');
          }
        } catch (error) {
          console.error('Error uploading image:', error);
        }
        setLoading(false);
      }
    };

    const formData = {}
    formData.id = tagId;
    formData.word = tagName;
    formData.background_color = backgroundColor;
    formData.text_color = textColor;
    formData.is_public = isPublic;

    try {
      let response;
      if (tagId !== 0) {
        response = await fetchWrapperRef.current.put(`${config.apiBaseUrl}/bands/${bandid}/tags/${tagId}`, formData)
      } else {
        response = await fetchWrapperRef.current.post(`${config.apiBaseUrl}/bands/${bandid}/tags`, formData)
      }

      const newTagId = tagId !== 0 ? tagId : response.data.id; // Assuming the response contains the new tag ID
      await uploadImage(newTagId);
    } catch (error) {
      console.error('Error creating tag:', error);
    }

    onRequestClose();
    setLoading(false);
  }, [bandid, backgroundColor, fetchWrapperRef, isPublic, onRequestClose, tagImage, tagName, textColor, tagId]);

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} className="modalCreateTag" overlayClassName="overlayCreateTag">
      <h2>{tag ? 'Edit Tag' : 'Create Tag'}</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Tag Name</label>
          <input type="text" value={tagName} onChange={(e) => setTagName(e.target.value)} required />
        </div>
        <div className="form-group color-inputs">
          <div className="color-input">
            <label>Background</label>
            <input type="color" value={backgroundColor} onChange={(e) => setBackgroundColor(e.target.value)} required />
          </div>
          <div className="color-input">
            <label>Text</label>
            <input type="color" value={textColor} onChange={(e) => setTextColor(e.target.value)} required />
          </div>
        </div>
        <div className="form-group">
          <label>Tag Image (optional)</label>
          <div className="image-upload" onDrop={handleDrop} onDragOver={handleDragOver}>
            <label htmlFor="file-upload" className="custom-file-upload">
              Upload or Drop Image
            </label>
            <input id="file-upload" type="file" onChange={handleImageUpload} accept='.jpg, .png' />
            {tagImageUrl && <img src={tagImageUrl} alt="Tag Icon Preview" className="tag-preview-image" />}
          </div>
        </div>
        <div className="form-group switch-group">
            <label>Display publicly</label>
            <Switch isOn={isPublic} handleToggle={() => setIsPublic(!isPublic)} switchId='switchIsPublic' />
        </div>
        <div className="button-group">

          {loading ? (
          <LoadingSpinner />) : (
            <button type="submit" className="btn-save">Save</button>
          )}
          <button type="button" onClick={onRequestClose} className="btn-cancel">Cancel</button>
        </div>
      </form>
      <h3 className="tag-preview-label">Tag Preview</h3>
      <div className="tag-preview" style={{ backgroundColor, color: textColor }}>
        {tagImageUrl && <img src={tagImageUrl} alt="Tag Preview" className="tag-preview-image" />}
        <span>{tagName}</span>
      </div>
    </Modal>
  );
};

export default CreateTagModal;