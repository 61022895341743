import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import Switch from '../../Switch';
import LoadingSpinner from '../LoadingSpinner';
import '../../../styles/partial/modal/EditSongModal.css';

const EditSongModal = ({ song, isOpen, onClose, onSave }) => {
  const [title, setTitle] = useState('');
  const [artist, setArtist] = useState('');
  const [lyrics, setLyrics] = useState('');
  const [chords, setChords] = useState('');
  const [tempo, setTempo] = useState('');
  const [key, setKey] = useState('');
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (song) {
      setTitle(song.Title);
      setArtist(song.Artist);
      setLyrics(song.Lyrics);
      setChords(song.Chords);
      setTempo(song.Tempo);
      setKey(song.Key);
      setActive(song.Active);
    }
  }, [song]);

  const handleSave = async (e) => {
    e.preventDefault();
    setLoading(true);
    await onSave({ ...song, Title: title, Artist: artist, Lyrics: lyrics, Chords: chords, Tempo: tempo, Key: key, Active: active });
    setLoading(false);
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className="modalEditSongOverlay" overlayClassName="modalEditSongContent">
      <h2>Edit Song</h2>
      <form onSubmit={handleSave}>
        <div className="form-group">
          <label>Title</label>
          <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} readOnly />
        </div>
        <div className="form-group">
          <label>Artist</label>
          <input type="text" value={artist} onChange={(e) => setArtist(e.target.value)} readOnly />
        </div>
        <div className="form-group">
          <label>Lyrics</label>
          <textarea value={lyrics} onChange={(e) => setLyrics(e.target.value)} />
        </div>
        <div className="form-group">
          <label>Chords</label>
          <textarea value={chords} onChange={(e) => setChords(e.target.value)} />
        </div>
        <div className="form-group">
          <label>Tempo</label>
          <input type="text" value={tempo} onChange={(e) => setTempo(e.target.value)} />
        </div>
        <div className="form-group">
          <label>Key</label>
          <input type="text" value={key} onChange={(e) => setKey(e.target.value)} />
        </div>
        <div className="form-group switch-group">
          <label>Active</label>
          <Switch isOn={active} handleToggle={() => setActive(!active)} switchId='switchIsActive' />
        </div>
        <div className="button-group">
          {loading ? (
            <LoadingSpinner />
          ) : (
            <button type="submit" className="btn-save">Save</button>
          )}
          <button type="button" onClick={onClose} className="btn-cancel">Cancel</button>
        </div>
      </form>
    </Modal>
  );
};

export default EditSongModal;