import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import config from '../config';
import '../styles/PerformerHome.css';

import { FaUserCircle } from 'react-icons/fa';

import Logo from './Logo';
import TopPageNavigation from './TopPageNavigation';
import TopPageNavigationBand from './TopPageNavigationBand'
import MainNavigation from './MainNavigation';

import { useFetchWrapper } from '../_helpers/fetchWrapper';

const PerformerHome = () => {
  const { bandid } = useParams();
  const [band, setBand] = useState(null);
  const fetchWrapperRef = useRef(useFetchWrapper());

  var isMember = useRef(false);

  
  const fetchBandData = useCallback(async () => {
    try {
      const data = await fetchWrapperRef.current.get(`${config.apiBaseUrl}/bands/${bandid}`);
      setBand(data);
      sessionStorage.setItem('bandId', data.ID);
      isMember.current = data.Members.some(member => member.UserID.toString() === sessionStorage.getItem('userId'));
    } catch (error) {
      console.error('Error fetching band data:', error);
    }

  }, [bandid]);

  useEffect(() => {
    fetchBandData();
  }, [fetchBandData]);

  if (!band) {
    return <div>Loading...</div>;
  }

  return (
    <div className="performer-home-page">
      <div className="performer-home-page-header"><Logo /></div>
      <div className="performer-home-page-wrapper">
        {isMember.current ? (
            <TopPageNavigationBand />
        ) : (
            <TopPageNavigation />
        )}
        <div className="performer-home-page-featured-area">
          <div className="performer-home-page-content">
            <div className="performer-home-profile">
                <div className="performer-home-page-content-title">
                  <h2 className="performer-home-page-title">{band.Name}</h2>
                </div>
              {band.ProfileImageURL ? (
                <img
                  src={band.ProfileImageURL}
                  alt={`${band.Name} profile`}
                  className="performer-home-profile-image"
                />
              ) : (
                <FaUserCircle className="performer-home-profile-image" />
              )}
              <p>{band.Description}</p>
              <p>Location: {band.Location}</p>
              <p>Website: <a href={band.WebsiteURL} target="_blank" rel="noopener noreferrer">{band.WebsiteURL}</a></p>
              <p>Contact Email: <a href={`mailto:${band.ContactEmail}`}>{band.ContactEmail}</a></p>
            </div>
            <div className='performers-home-profile-center'>
                <div className='performers-home-profile-event'>
                    <h3>Upcoming Event:</h3>
                </div>
                <div className="performer-home-profile-members">
                  <h3>Members</h3>
                  {band.Members.map(member => (
                    <div key={member.UserID} className="performer-home-profile-member">
                      {member.ProfileImageURL ? (
                          <img
                          src={member.ProfileImageURL}
                          alt={`${member.Username} profile`}
                          className="performer-home-member-profile-image"
                          />
                      ) : (
                          <FaUserCircle className="performer-home-member-profile-image" />
                      )}
                      <p>{member.Username}</p>
                    </div>
                  ))}
                </div>
                <div className="performer-home-profile-followers">
                  <h3>Followers:</h3>
                  <ul>
                      <li>Total followers:</li>
                      <li>Last 5 followers:</li>
                      <li>Longest follower:</li>
                      <li>Newest follower:</li>
                  </ul>
                </div>
            </div>
          </div>
          {!isMember.current &&
            <div className='performer-home-side-navigation'>
              <MainNavigation />
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default PerformerHome;