import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa';
import Logo from './Logo';
import TopPageNavigation from './TopPageNavigation';
import LoadingSpinner from './partial/LoadingSpinner';
import '../styles/RegisterBand.css';
import config from '../config';
import { useFetchWrapper } from '../_helpers/fetchWrapper';

import '../App.css';

function RegisterBand() {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [location, setLocation] = useState('');
  const [websiteURL, setWebsiteURL] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [socialMediaLinks, setSocialMediaLinks] = useState(['']);
  const [defaultloginPage, setDefaultloginPage] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchWrapperRef = useRef(useFetchWrapper());

  const navigate = useNavigate();

  const handleAddSocialMediaLink = () => {
    setSocialMediaLinks([...socialMediaLinks, '']);
  };

  const handleSocialMediaLinkChange = (index, value) => {
    const newLinks = [...socialMediaLinks];
    newLinks[index] = value;
    setSocialMediaLinks(newLinks);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    await fetchWrapperRef.current.post(`${config.apiBaseUrl}/bands`, {
      name,
      description,
      location,
      websiteURL,
      contactEmail,
      socialMediaLinks,
      defaultloginPage
    });

    navigate('/performers');
    setLoading(false);
  };

  return (
    <div className="register-band-page">
      <div className="register-band-page-header"><Logo /></div>
      <div className="register-band-page-wrapper">
        <TopPageNavigation />
        <div className="register-band-page-featured-area">
          <div className="register-band-page-content">
            <div className="register-band-page-content-title">
              <h2 className="register-band-page-title">Register New Performer / Group</h2>
            </div>
            <div className="register-band-page-content-description">
              IMPORTANT: The following information will be publicly displayed on your performer profile page.
            </div>
            <form className="register-band-form" onSubmit={handleSubmit}>
              <label>
                Name:
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="register-band-input"
                  required
                />
              </label>
              <label>
                Description:
                <textarea
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  className="register-band-input"
                  required
                />
              </label>
              <label>
                Location:
                <input
                  type="text"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                  className="register-band-input"
                  required
                />
              </label>
              <label>
                Website URL:
                <input
                  type="url"
                  value={websiteURL}
                  onChange={(e) => setWebsiteURL(e.target.value)}
                  className="register-band-input"
                />
              </label>
              <label>
                Contact Email:
                <input
                  type="email"
                  value={contactEmail}
                  onChange={(e) => setContactEmail(e.target.value)}
                  className="register-band-input"
                  required
                />
              </label>
              <label>
                Social Media Links:
                {socialMediaLinks.map((link, index) => (
                  <input
                    key={index}
                    type="url"
                    value={link}
                    onChange={(e) => handleSocialMediaLinkChange(index, e.target.value)}
                    className="register-band-input"
                  />
                ))}
                <button type="button" onClick={handleAddSocialMediaLink} className="register-band-form-button">
                  <FaPlus /> Add
                </button>
              </label>
              <div className="checkbox-container">
                <input
                    type="checkbox"
                    value={true}
                    className="register-band-input"
                    id='default-login-page'
                    onChange={(e) => setDefaultloginPage(e.target.value)}
                    />
                <label for="default-login-page"> 
                  Make default login page? This can be changed in your user profile settings.
                </label>
              </div>
              <div className="register-band-form-buttons">
                <button type="button" className="register-band-form-button" onClick={() => navigate('/performers')}>
                  Cancel
                </button>

                {loading ? (
                  <LoadingSpinner />
                ) : (
                  <button type="submit" className="register-band-form-button">
                    Create
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegisterBand;