import React, { useEffect, useState } from 'react';
import '../styles/LoginPage.css';  // Import styles
import { FaGoogle, FaTwitch, FaSpotify, FaAndroid } from 'react-icons/fa';
import Logo from './Logo';
import config from '../config';
import CryptoJS from 'crypto-js';

import LoadingSpinner from './partial/LoadingSpinner';

function LoginPage() {

  const { apiBaseUrl } = config;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Generate a random 32-character alphanumeric string
    const generateVerifier = () => {
      const array = new Uint32Array(32);
      window.crypto.getRandomValues(array);
      return Array.from(array, dec => ('0' + dec.toString(36)).substr(-2)).join('');
    };

    // Create an S256 hash of the random string
    const generateChallenge = async (verifier) => {
      const hash = CryptoJS.SHA256(verifier);

      return CryptoJS.enc.Base64.stringify(hash)
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=+$/, '');
    };

    const verifier = generateVerifier();
    sessionStorage.setItem('verifier', verifier);

    generateChallenge(verifier).then(challenge => {
      sessionStorage.setItem('challenge', challenge);
      setLoading(false);
    });


  }, []);

  const handleGoogleLogin = () => {
    const challenge = sessionStorage.getItem('challenge');
    sessionStorage.removeItem('challenge');
    sessionStorage.setItem('oauth-provider', 'google');
    window.location.href = `${apiBaseUrl}/auth/google/login?challenge=${challenge}`;
  };

  const handleTwitchLogin = () => {
    const challenge = sessionStorage.getItem('challenge');
    sessionStorage.removeItem('challenge');
    sessionStorage.setItem('oauth-provider', 'twitch');
    window.location.href = `${apiBaseUrl}/auth/twitch/login?challenge=${challenge}`;
  };
  

  const handleDevLogin = () => {
    sessionStorage.setItem('oauth-provider', 'dev');
    window.location.href = `${apiBaseUrl}/auth/dev/login`;
  };

  return (
    <div className="login-page">
      <Logo />

      {loading ? (
        <LoadingSpinner />
      ) : (

      <div className="login-page-login-buttons">
        <button className="login-page-login-button login-page-google-login-button"
          onClick={handleGoogleLogin}
        >
          <FaGoogle className="login-page-login-icon" />
          <span className="login-text">Login with Google</span>
        </button>
        <button className="login-page-login-button login-page-twitch-login-button"
        onClick={handleTwitchLogin}>
          <FaTwitch className="login-page-login-icon" />
          <span className="login-text">Login with Twitch</span>
        </button>
        <button className="login-page-login-button login-page-spotify-login-button">
          <FaSpotify className="login-page-login-icon" />
          <span className="login-text">Login with Spotify</span>
        </button>
        {process.env.NODE_ENV === 'development' && (
        <button className="login-page-login-button login-page-dev-login-button"
        onClick={handleDevLogin}>
          <FaAndroid className="login-page-login-icon" />
          <span className="login-text">Login with Dev</span>
        </button>
        )}
      </div>

    )}
    </div>
  );
}

export default LoginPage;