import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { FaUsers } from 'react-icons/fa';
 
import { useFetchWrapper } from '../_helpers/fetchWrapper';
import config from '../config';

import Logo from './Logo';
import '../styles/PerformersSearchPage.css';
import MainNavigation from './MainNavigation';
import TopPageNavigation from './TopPageNavigation';

import '../App.css';

function PerformersSearchPage() {
  const [bandName, setBandName] = useState('');
  const [location, setLocation] = useState('');
  const [myBands, setMyBands] = useState([]);
  const [recommendedBands, setRecommendedBands] = useState([]);
  const navigate = useNavigate();

  const fetchWrapperRef = useRef(useFetchWrapper());

  useEffect(() => {
    const fetchMyBands = async () => {

      const data = await fetchWrapperRef.current.get(`${config.apiBaseUrl}/user/mybands`)
      setMyBands(data);

      const recommendedBands = await fetchWrapperRef.current.get(`${config.apiBaseUrl}/bands/recommended`)
      setRecommendedBands(recommendedBands);

    };

    fetchMyBands();
  }, [navigate]);

  const handleSearch = () => {
    if (!bandName && !location) {
      alert('Please fill in at least one search field.');
      return;
    }
    // Perform search logic here
  };

  return (
    <div className="performers-search-page">
      <div className="performers-search-page-header"><Logo /></div>
      <div className="performers-search-page-wrapper">
        <TopPageNavigation />
        <div className="performers-search-page-featured-area">
            <div className="performers-search-page-band-search-area">
              <div className="performers-search-page-content">
                {myBands.length > 0 && (
                  <>
                    <h2>My Performing Groups</h2>
                    <div className='performers-search-page-my-bands'>
                      {myBands.map(band => (
                        <div key={band.ID} className="my-band-item">
                          <Link to={`/performers/${band.ID}`}>
                            <div className="my-band-image">
                              {band.ProfileImageURL ? (
                                <img
                                  src={band.ProfileImageURL}
                                  alt={`${band.Name} profile`}
                                  className="band-profile-image"
                                />
                              ) : (
                                <FaUsers className="band-profile-image" />
                              )}
                            </div>
                            <div className='my-band-name'>
                              {band.Name}
                            </div>
                          </Link>
                        </div>
                      ))}
                    </div>
                  </>
                )}


                {recommendedBands.length > 0 && (
                  <>
                  <h2>You may enjoy</h2>
                  <div className='performers-search-page-my-bands'> 
                    {recommendedBands.map(band => (
                      <div key={band.ID} className="my-band-item">
                        <Link to={`/performers/${band.ID}`}>
                          <div className="my-band-image">
                            {band.ProfileImageURL ? (
                              <img
                                src={band.ProfileImageURL}
                                alt={`${band.Name} profile`}
                                className="band-profile-image"
                              />
                            ) : (
                              <FaUsers className="band-profile-image" />
                            )}
                          </div>
                          <div className='my-band-name'>
                            {band.Name}
                          </div>
                        </Link>
                      </div>
                    ))}
                  </div>
                  </>
                )}
                <div className="performers-search-page-content-title">
                  <h2 className="performers-search-page-title">Search for Performers</h2>
                  <button className="performers-search-page-new-band-button" onClick={() => navigate('/register-band')}>Register as a performer?</button>
                </div>
                <div className="performers-search-form">
                    <label>
                    Name:
                    <input
                        type="text"
                        value={bandName}
                        onChange={(e) => setBandName(e.target.value)}
                        className="performers-search-input"
                    />
                    </label>
                    <label>
                    Location:
                    <input
                        type="text"
                        value={location}
                        onChange={(e) => setLocation(e.target.value)}
                        className="performers-search-input"
                    />
                    </label>
                </div>
                <button className="performers-search-page-form-buttons" onClick={handleSearch}>Search</button>
              </div>
            </div>
            <MainNavigation /> 
        </div>
      </div>
    </div>
  );
}

export default PerformersSearchPage;