import config from '../config';
import { useNavigate } from 'react-router-dom';

export { useFetchWrapper };

function useFetchWrapper() {

    const navigate = useNavigate();

    return {
        get: request('GET'),
        post: request('POST'),
        put: request('PUT'),
        delete: request('DELETE')
    };

    function request(method) {
        return (url, body, cache = false) => {

            if (cache) {
                cache = 'force-cache';
            } else {
                cache = 'no-store';
            }

            const requestOptions = {
                method,
                headers: authHeader(url),
                cache: cache,
            };

            if (body) {
                if (body instanceof FormData) {
                    // Do not set Content-Type header for FormData
                    delete requestOptions.headers['Content-Type'];
                    requestOptions.body = body;
                } else {
                    requestOptions.headers['Content-Type'] = 'application/json';
                    requestOptions.body = JSON.stringify(body);
                }
            }
            return fetch(url, requestOptions).then(handleResponse);
        }
    }
    
    // helper functions
    
    function authHeader(url) {
        // return auth header with jwt if user is logged in and request is to the api url
        const token = sessionStorage.getItem('jwt');
        const isLoggedIn = !!token;
        const isApiUrl = url.startsWith(config.apiBaseUrl);
        if (isLoggedIn && isApiUrl) {
            return { Authorization: `Bearer ${token}` };
        } else {
            return {};
        }
    }
    
    function handleResponse(response) {
        return response.text().then(text => {
            
            var data 
            
            try {
                data = text && JSON.parse(text);
            }
            catch (e) {
                data = text;
            }
            
            if (!response.ok) {
                if ([401, 403].includes(response.status) && sessionStorage.getItem('jwt')) {
                    // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                    sessionStorage.removeItem('jwt');
                    sessionStorage.removeItem('ProfileImageURL');
                    sessionStorage.removeItem('userId');
                    sessionStorage.removeItem('theme');

                    navigate('/login');
                }
    
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }
    
            return data;
        });
    }    
}