import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useDrag } from 'react-dnd';
import CreateTagModal from '../modal/CreateTagModal';
import { FaSearch, FaPlus, FaLock, FaGlobe, FaEdit, FaTrash, FaArrowsAlt } from 'react-icons/fa';
import { useFetchWrapper } from '../../../_helpers/fetchWrapper';
import { useReload } from '../../../contexts/ReloadContext';

import config from '../../../config';

export const ItemTypes = {
  TAG: 'tag',
};

const DraggableTag = ({ tag, handleEdit, handleDelete }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.TAG,
    item: { tag },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <div
      ref={drag}
      style={{
        backgroundColor: tag.BackgroundColor,
        color: tag.TextColor,
        opacity: isDragging ? 0.5 : 1,
      }}
      className='tags-content-container'
    >
      <FaArrowsAlt />
      {tag.ImageURL && <img src={config.mediaBaseUrl + '/' + tag.ImageURL} alt="Icon for tag" className="tag-image" />}
      <span>{tag.Word}</span>
      <div>
        <FaEdit className="icon" onClick={() => handleEdit(tag)} style={{ cursor: 'pointer', marginRight: '10px' }} />
        <FaTrash className="icon" onClick={() => handleDelete(tag.ID)} style={{ cursor: 'pointer' }} />
      </div>
    </div>
  );
};

const PerformerNavEditTags = () => {
  const { bandid } = useParams();
  const fetchWrapperRef = useRef(useFetchWrapper());
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [tags, setTags] = useState([]);
  const [selectedTag, setSelectedTag] = useState(null);
  const reloadChildren = useReload();

  const openModal = (tag = null) => {
    setSelectedTag(tag);
    setModalIsOpen(true);
  };

  const closeModal = async () => {
    setModalIsOpen(false);
    setSelectedTag(null);
    await fetchTags();
    reloadChildren();
  };

  const fetchTags = useCallback(async () => {
    try {
      const response = await fetchWrapperRef.current.get(`${config.apiBaseUrl}/bands/${bandid}/tags`);
      setTags(response);
    } catch (error) {
      console.error('Error fetching tags:', error);
    }
  }, [bandid]);

  useEffect(() => {
    fetchTags();
  }, [fetchTags]);

  const handleEdit = (tag) => {
    openModal(tag);
  };

  const handleDelete = async (tagId) => {
    try {
      await fetchWrapperRef.current.delete(`${config.apiBaseUrl}/bands/${bandid}/tags/${tagId}`);
      await fetchTags();
      reloadChildren();
    } catch (error) {
      console.error('Error deleting tag:', error);
    }
  };

  return (
    <div className="performer-page-search-container">
      <input type="text" placeholder="Search Tag" className="performer-page-search-input" />
      <FaSearch className="performer-page-search-icon" />
      <button onClick={() => openModal()} className="performer-page-search-button">
        <FaPlus className="icon" /> Create Tag
      </button>
      <div><FaLock className="icon" /> Private Tags</div>
      <div className="tags-container">
        {tags.filter(tag => !tag.IsPublic).map(tag => (
          <DraggableTag key={tag.ID} tag={tag} handleEdit={handleEdit} handleDelete={handleDelete} />
        ))}
      </div>
      <div><FaGlobe className="icon" /> Public Tags</div>
      <div className="tags-container">
        {tags.filter(tag => tag.IsPublic).map(tag => (
          <DraggableTag key={tag.ID} tag={tag} handleEdit={handleEdit} handleDelete={handleDelete} />
        ))}
      </div>

      <CreateTagModal isOpen={modalIsOpen} onRequestClose={closeModal} tag={selectedTag} />
    </div>
  );
};

export default PerformerNavEditTags;