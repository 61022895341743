import { useParams, Link } from 'react-router-dom';

import { FaSearch, FaPlus, FaFileImport, FaCog, FaRobot, FaTags } from 'react-icons/fa';

const PerformerNavSongList = () => {

    const { bandid } = useParams();

    return (
        <div className="performer-page-search-container">
            <input type="text" placeholder="Search Song" className="performer-page-search-input" />
            <FaSearch className="performer-page-search-icon" />
            <Link to={`/performers/${bandid}/add-song`} className="performer-page-search-button"><FaPlus className="icon" /> Add Song</Link>
            <a href="#import-songlist"><FaFileImport className="icon" /> Import Songlist</a>
            <Link to={`/performers/${bandid}/edit-tags`} className="performer-page-search-button"><FaTags className="icon" /> Edit Tags</Link>
            <a href="#settings"><FaCog className="icon" /> Settings</a>
            <a href="#ai-options"><FaRobot className="icon" /> AI Options</a>
        </div>
    );
};

export default PerformerNavSongList;