import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaSave } from 'react-icons/fa';
import config from '../../config';
import LoadingSpinner from './LoadingSpinner';
import '../../styles/partial/AddSong.css';

import { useFetchWrapper } from '../../_helpers/fetchWrapper';

const AddSong = () => {
  const [song, setSong] = useState({
    Title: '',
    Artist: '',
    Lyrics: '',
    Chords: '',
    Tempo: '',
    Key: '',
    Active: false,
  });


  const [artistOrTitleChanged, setArtistOrTitleChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [additionalFieldsVisible, setAdditionalFieldsVisible] = useState(false);

  const navigate = useNavigate();
  const fetchWrapper = useFetchWrapper();

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'Title' || name === 'Artist') {
      setArtistOrTitleChanged(true);
    }

    setSong((prevSong) => ({
      ...prevSong,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      var bandId = sessionStorage.getItem('bandId');
      await fetchWrapper.put(`${config.apiBaseUrl}/bands/${bandId}/songs`, song);
      navigate(`/performers/${bandId}/song-list`);
    } catch (error) {
      console.error('Error adding song:', error);
    }
  };

  const searchForMetaData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetchWrapper.get(`${config.apiBaseUrl}/songs/metadata?title=${song.Title}&artist=${song.Artist}`);
      if (response.status === 404) {
        console.info('No metadata found for song:', song.Title, song.Artist);
      } else {
        const data = await response.json();
        
        //populate the form with the metadata
        setSong((prevSong) => ({
          ...prevSong,
          Lyrics: data.lyrics || '',
          Chords: data.chords || '',
          Tempo: data.tempo || '',
          Key: data.key || '',
        }));
      }
    } catch (error) {
      console.error('Error fetching metadata:', error);
    } finally {
      setLoading(false);
      setAdditionalFieldsVisible(true);
      setArtistOrTitleChanged(false);
    }
  }, [fetchWrapper, song.Title, song.Artist]);

  useEffect(() => {
    if (song.Title && song.Artist && artistOrTitleChanged) {
      searchForMetaData();
    }
  }, [song.Title, song.Artist, searchForMetaData, artistOrTitleChanged]);

  return (
    <div className="add-song">
      <h1>Add New Song</h1>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Title</label>
          <input type="text" name="Title" value={song.Title} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <label>Artist</label>
          <input type="text" name="Artist" value={song.Artist} onChange={handleChange} required />
        </div>
        {!additionalFieldsVisible && loading && <LoadingSpinner />}
        {additionalFieldsVisible && (
          <>
            <div className="form-group">
              <label>Lyrics</label>
              <textarea name="Lyrics" value={song.Lyrics} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Chords</label>
              <textarea name="Chords" value={song.Chords} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Tempo</label>
              <input type="text" name="Tempo" value={song.Tempo} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Key</label>
              <input type="text" name="Key" value={song.Key} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Public</label>
              <input type="checkbox" name="Active" checked={song.Active} onChange={(e) => setSong((prevSong) => ({ ...prevSong, Active: e.target.checked }))} />
            </div>
            <button type="submit" className="btn-save"><FaSave /> Save</button>
          </>
        )}
      </form>
    </div>
  );
};

export default AddSong;