import React, { createContext, useContext } from 'react';

const ReloadContext = createContext();

export const useReload = () => useContext(ReloadContext);

export const ReloadProvider = ({ children, value }) => (
  <ReloadContext.Provider value={value}>
    {children}
  </ReloadContext.Provider>
);